import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async fix(event) {
    if (event.pointerType) {
      // Hack for modal :( TODO: Fix Modals
      return
    }

    Rails.ajax({
      url: `/resource_changes/${event.params.id}/fix`,
      type: 'POST'
    })
  }

  async revert(event) {
    if (event.pointerType) {
      // Hack for modal :( TODO: Fix Modals
      return
    }

    Rails.ajax({
      url: `/resource_changes/${event.params.id}/revert`,
      type: 'POST'
    })
  }
}
