import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "showtarget" ]

  connect() {
    this.eval()
  }

  async eval(event) {
    this.showtargetTargets.forEach(element => {
      if (this.checkboxTarget.checked) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    })
  }
}
