import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "enabletarget" ]

  connect() {
    this.eval()
  }

  async eval(event) {
    if (this.checkboxTarget.checked) {
      this.enabletargetTargets.forEach(element => {
        element.removeAttribute("disabled")
      });
    } else {
      this.enabletargetTargets.forEach(element => {
        element.setAttribute("disabled", true)
      });
    }
  }
}
