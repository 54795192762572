
import tippy from "tippy.js";


import { Controller } from "@hotwired/stimulus"


// this global id is a cheap hack to prevent double registering
// of click events
let gid = 0
export default class extends Controller {

  connect() {
    this.id = ++gid
    this.tips = {}
    Rails.ajax({
      url: `/tour_markers`,
      type: 'GET',
      success: (res) => {
        // hack to prevent duplicate rendering on race conditions
        if (this.id === gid) {
          this.markers = res
          this.renderMarkers()
        }
      }
    })
    this.flashOn = false
    this.flashIntervals = []
    this.clickListeners = []
  }

  disconnect() {
    this.flashIntervals.forEach(interval => window.clearInterval(interval))
  }

  start(event) {
    Rails.ajax({
      url: `/tours/${event.params.id}/start.json`,
      type: 'POST',
      success: (res) => {
        window.location = res.tour_step.path
      }
    })
  }

  createTipElement(step, img) {
    const outerDiv = document.createElement('div')
    outerDiv.classList.add('p-2')

    const stepTitle = document.createElement('div')
    stepTitle.textContent = step.title
    stepTitle.classList.add('mb-2', 'font-bold', 'underline')
    outerDiv.appendChild(stepTitle)

    const stepInstructions = document.createElement('div')
    stepInstructions.textContent = step.instructions
    outerDiv.appendChild(stepInstructions)

    return outerDiv
  }

  elementIsTextField(element) {
    return element.tagName === 'INPUT' || element.tagName === 'TEXTAREA'
  }

  renderTip(anchorElem, step) {
    const tip = tippy(anchorElem, {
      content: this.createTipElement(step),
      hideOnClick: false,
      theme: 'tour'
    })
    tip.show()
  }

  decorateElements(promptSelector, advanceSelector, marker) {
    const advanceElements = document.querySelectorAll(advanceSelector)
    const promptElements = document.querySelectorAll(promptSelector)
    const highlightClasses = ['outline-dashed', 'outline-4', 'outline-lime-400', 'outline-offset-2']

    const variables = marker.tour_step.variables
    const AdvanceElemAttrVars = variables.filter(v => v.value_source === 'advance_element_attribute')
    const globalVars = {}
    advanceElements.forEach((element) => {
      const elemVars = {}
      AdvanceElemAttrVars.forEach((aev) => {
        elemVars[aev.key] = element.getAttribute(aev.value_source_config)
      })
      element.addEventListener('click', (event) => {
        Rails.ajax({
          url: `/tours/${marker.tour.id}/advance`,
          type: 'POST',
          data: new URLSearchParams({
            variables: JSON.stringify({...globalVars, ...elemVars})
          }).toString()
        })
      })
    })

    promptElements.forEach((element) => element.classList.add(...highlightClasses))

    const interval = window.setInterval(() => {
      this.flashOn = !!!this.flashOn
      if (this.flashOn) {
        promptElements.forEach((element) => {
          if (this.elementIsTextField(element) && element.value !== '') {
            return // Skip flash for non-empty inputs
          }
          element.classList.add(...highlightClasses)
        })
      } else {
        promptElements.forEach(element => element.classList.remove(...highlightClasses))
      }
    }, 1000)
    this.flashIntervals.push(interval)
  }

  renderStep(step, marker) {
    // If you have both advance and prompt, use them, otherwise default them to each other
    const advanceSelector = step.advance_selector || step.prompt_selector
    const promptSelector = step.prompt_selector || step.advance_selector

    const firstPrompt = document.querySelector(promptSelector)
    this.renderTip(firstPrompt, step)
    this.decorateElements(promptSelector, advanceSelector, marker)
  }

  renderMarkers() {
    this.markers.forEach((marker) => {
      const step = marker.tour_step
      const path = `${window.location.pathname}${window.location.search}`
      if (!step) return
      if (path.match(step.path_regex)) {
        this.renderStep(step, marker)
      } else {
        console.log('no match', path, step.path_regex)
      }
    })
  }
}
