import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  async execute(event) {
    if (event.pointerType) {
      // Hack for modal :( TODO: Fix Modals
      return
    }

    Rails.ajax({
      url: `/policies/${event.params.id}/execute`,
      type: 'POST'
    })
  }
}
